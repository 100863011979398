import React from 'react'
import Fade from 'react-reveal/Fade'
import Accordion from './Accordion'

import './questions.scss'

const Questions = ({ ...props }) => {

    return(
        <div className="questions">
            <div className="questions__container">

                <Fade bottom cascade>
                    { props.data.map((item) => (
                        <div id={`question-${item.directusId}`} key={ item.directusId }>
                            <Accordion
                                pergunta={ item.pergunta }
                                imagem={ item.imagem.localFile.name }
                                resposta={ item.resposta } />
                        </div>
                    ))}
                </Fade>
            
            </div>
        </div>
    )
}

export default Questions
