import React from 'react'
import Image from '../Image'

const Accordion = ({ ...props }) => {
    function createMarkup() {
        return { __html: props.resposta }
    }

    return(
        <div className="questions__content">
            
            <div className="questions__ask">
                <h3>{ props.pergunta }</h3>
            </div>

            <div className="questions__answer">

                <figure>
                    <Image filename={ props.imagem } alt="Grupo Reinserir" />
                </figure>

                <div className="answer__content">
                    <div dangerouslySetInnerHTML={createMarkup()} />
                </div>
            </div>
        </div>
    )
}

export default Accordion
