import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

import MainTitle from '../components/MainTitle'
import Content from '../components/Content'
import Questions from '../components/Questions'


const QuemSomos = ({ data: { directusPerguntasFrequente: perguntas, allDirectusQuestoe: questoes } }) => (
  <Layout>
    <SEO title="Perguntas Frequentes" />

    <MainTitle
        content={ perguntas.titulo }  />
    
    <Content
        content={ perguntas.introducao } />

    <Questions
        data={ questoes.nodes } />
  
  </Layout>
)

export const query = graphql`
  {
    directusPerguntasFrequente {
      titulo
      introducao
    }
    allDirectusQuestoe {
      nodes {
        directusId
        pergunta
        resposta
        imagem {
          localFile {
            name
          }
        }
      }
    }
  }
`


export default QuemSomos
